<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入情報編集（処分）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">契約情報</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>代理登録</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="auto-update-true"
                    type="radio"
                    name="auto-update"
                    :value="true"
                    v-model="formEdit.isDisposalProxy"
                  />
                  <label class="c-radio__label" for="auto-update-true">
                    <span class="c-radio__box"></span>
                    有り
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="auto-update-false"
                    type="radio"
                    name="auto-update"
                    :value="false"
                    v-model="formEdit.isDisposalProxy"
                  />
                  <label class="c-radio__label" for="auto-update-false">
                    <span class="c-radio__box"></span>
                    無し
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="l-btns" style="margin-top: 20rem">
          <div class="c-btn secondary small" @click="backHandler()">戻る</div>
          <div class="c-btn primary small" @click="formHandler()">登録</div>
        </div>
      </div>

      <!--登録確認のモーダル-->
      <div v-if="isDisplayConfirm" class="c-modal md">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            CBA加入情報（処分）を編集します。<br />
            よろしいですか？
          </div>
          <ModalFooterButtons :processing="processing">
            <div class="c-btn secondary small" @click="isDisplayConfirm = false">戻る</div>
            <div class="c-btn primary small" @click="confirmHandler()">はい</div>
          </ModalFooterButtons>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import { API_URL } from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "disposer-cbacontract-edit",
  components: {ModalFooterButtons},
  mixins: [common, validation, formValueEdit, callApi],
  data() {
    return {
      isDisplayConfirm: false,
      errMsgs: [],
      processing: false,
    };
  },
  methods: {
    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      axios
        .put(API_URL.DISPOSER.CBACONTRACT_EDIT + this.$route.params.id, this.formEdit)
        .then(() => {
          this.$router.push("/operate");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    // フォームの登録押下
    formHandler() {
      this.isDisplayConfirm = true;
    },
    // モーダルの登録押下
    confirmHandler() {
      this.errMsgs = [];
      this.postForm();
    },
    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.disposalCompanyId = this.$route.params.id;
    this.getDisposerInfoApi(this.disposalCompanyId);
  },
};
</script>
