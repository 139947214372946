export default {
    data() {
      return {
        // CBA加入編集のフォーム
        formEdit: {
          isCBAContract: true,
          contractStatusTypeId: "",
          isDisposalProxy: true,
        },
      };
    },
  };  